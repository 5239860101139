<template>
  <div class="body">
      <div class="bg">
        <a-row >
            <a-col :xs="24" :sm="24" :md="12"   justify="center" align="middle">
              <img class="bg_left_logo" src="http://www.znffj.com/images/homepage/12.png" alt="">
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" >
                <div class="bg_right">
                  <h1>企业理念</h1>
                  <p>以产品为驱动力，技术为核心，市场为导向，为客户提供品质服务，为员工创造价值，服务社会，造福国人</p>
                </div>
            </a-col>
        </a-row>
        <a-row >
          <a-col :xs="24" :sm="24" :md="12" >
                <div class="bg_right">
                  <h1>企业愿景</h1>
                  <p>米茉让智能无处不在。</p>
                  <p>米茉让财富无处不在。</p>
                </div>
            </a-col>
            <a-col :xs="24" :sm="24" :md="12"   justify="center" align="middle">
              <img class="bg_left_logo" src="http://www.znffj.com/images/homepage/13.png" alt="">
            </a-col>

        </a-row>
        <a-row>
          <a-col :span="24" justify="center" align="middle" class="dititle">
          产品与服务
          </a-col>
        </a-row>
        <a-row class="padding-bottom">
          <a-col :xs="24" :sm="24" :md="8"  justify="center" align="middle" >
              <a-card hoverable size="small" :bordered="false">
                <img
                  slot="cover"
                  alt="example"
                  src="http://www.znffj.com/images/homepage/smartmachine.png"
                />
                <a-card-meta title="智能设备">
                  <template slot="description">
                    打造超标准的智能设备，打造万物互联的智能生活
                  </template>
                </a-card-meta>
              </a-card>
          </a-col>
           <a-col :xs="24" :sm="24" :md="8" justify="center" align="middle" >
              <a-card hoverable size="small" :bordered="false">
                <img
                  slot="cover"
                  alt="example"
                  src="http://www.znffj.com/images/homepage/cloud.png"
                />
                <a-card-meta title="智能云平台">
                  <template slot="description">
                    先进虚拟化以及容器技术打造系统平台，提供健壮可靠的软件服务
                  </template>
                </a-card-meta>
              </a-card>
          </a-col>
           <a-col :xs="24" :sm="24" :md="8" justify="center" align="middle" >
              <a-card hoverable size="small" :bordered="false">
                <img
                  slot="cover"
                  alt="example"
                  src="http://www.znffj.com/images/homepage/client.png"
                />
                <a-card-meta title="专业客户服务">
                  <template slot="description">
                   用心为客户提供超优质服务
                  </template>
                </a-card-meta>
              </a-card>
          </a-col>
        </a-row>

      </div>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ant-carousel >>> .slick-slide {
  overflow: hidden;
}
.ant-card{
 background:transparent;
}
.body{
 font-family: "PingFang SC",-apple-system,BlinkMacSystemFont,"Segoe UI","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.bg{
  height: calc(120vh);
  max-width: 1200px;
  margin: auto;
  will-change: transform;

}
.bg_left_logo{
  width: 50%;
  height: 50%;
  padding: 18px;
}
.bg_right{
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content:center;
  align-items: flex-start;
}
.bg_right>h1{
    font-size: 32px;
    font-weight: 400;
    color: #404040;
    line-height: 1.5715;
}
.bg_right>p{
  font-size: 18px;
  color: rgba(0,0,0,.65);

}
.dititle{
    font-size: 32px;
    color: rgba(0,0,0,.65);
}

.ant-card-cover>img{
  width: 128px;
  height: 128px;
}
.padding-bottom{
  padding: 48px;
}
@media screen and (max-width: 767px){
  .bg_left_logo{
    padding: 8px;
  }
  .bg_right{
     align-items: center;
  }
  .bg_right>p{
  text-align: center;
  width: 80%;
}
}
</style>
